<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
// import moment from 'moment';
import Cookies from "js-cookie";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "DIGITALISASI CV DIREKSI & DEKOM",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "DIGITALISASI CV DIREKSI & DEKOM",
      items: [
        {
          text: "Digitalisasi",
          href: "/",
        },
        {
          text: "Digitalisasi CV Direksi & Dekom",
          active: true,
        },
      ],
      // variable Page Table
      loadingTable: true,
      pagingTable: [],
      showDataToTable: "",
      showDataFromTable: "",
      totalDataTable: "",
      currentTablePage: "",
      searchDataTable: "",
      showModal: false,
      showModalAdd: false,
      showFormAdd: false,
      table_data: [],
      selected: [],
      tanggal: "",
      search: "",

      // stora Variable
      pn: "",
      nama: "",
      last_inserted_id: "",
      
      role_slug: Cookies.get("session_role_slug"),
    };
  },
  mounted() {
    this.getDataTable();
  },
  methods: {
    getDataTable() {
      let self = this;
      self.loadingTable = true;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "/api/digitalisasi/cv?role_slug=" +
            Cookies.get("session_role_slug") +
            "&id=" +
            Cookies.get("session_id") +
            "&search=" +
            self.search
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.table_data = response_data.list_data.data;
            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },
    hapusData(id) {
      var alert_text = "Apakah Anda yakin?";
      Swal.fire({
        title: "Warning ?",
        text: alert_text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
      }).then((result) => {
        if (result.isConfirmed) {
          let self = this;
          Swal.fire({
            title: '<i class="fas fa-spinner fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });
          var FormData = require("form-data");
          var data = new FormData();
          data.append("id", id);

          var config = {
            method: "post",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "/api/digitalisasi/cv/delete",
            data: data,
          };
          axios(config)
            .then(function (response) {
              console.log(response);
              let timerInterval;
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Anda akan diarahkan ke halaman digitalisasi cv segera",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  Swal.close();
                  self.getDataTable();
                }
              });
            })
            .catch((e) => {
              this.axiosCatchError = e.response.data.data;
              Swal.close();
            });
        } else {
          Swal.close();
        }
      });
    },

    StoreData() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });
      var FormData = require("form-data");
      var data = new FormData();
      data.append("pn", self.pn);
      data.append("nama", self.nama);
      data.append("id_session_user", Cookies.get("session_id"));
      data.append("status", 'simpan');

      var config = {
        method: "post",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION + "/api/digitalisasi/cv/store",
        data: data,
      };
      axios(config)
        .then(function (response) {
          var response_data = response.data; 
          //console.log(response_data);
          if(response_data.code != 200){
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: response_data.message,
            });
          }else{
            let timerInterval;
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Anda akan diarahkan ke halaman detail cv segera",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                //console.log(response_data);
                Swal.close();
                self.showModal = false;
                self.last_inserted_id = response_data.id;
                self.$router.push({ name: "detail-cv", params: { token: self.last_inserted_id } });
              }
            });
          }
        })
        .catch((e) => {
          this.axiosCatchError = e.response.data.data;
          Swal.close();
        });
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="text-end">
                  <button
                    type="button"
                    class="btn btn-info rounded-0"
                    @click="showModal = true"
                  >
                    <i class="fa fa-plus"></i>
                    Tambah Digitaliasi
                  </button>
                </div>
              </div>
              <div class="col-md-12 mt-3">
                <div class="row">
                  <div class="col-md-9"></div>
                  <div class="col-md-3">
                    <input
                      type="text"
                      class="form-control"
                      id="searchTable"
                      v-model="search"
                      @input="getDataTable()"
                      placeholder="Cari Data ..."
                    />
                  </div>
                </div>
                <br />
              </div>
              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table mb-0 table-bordered table-condensed table-hover table-striped">
                    <thead>
                      <tr>
                        <th class="text-center text-white" style="width: 50px;background-color: #132d4a">No</th>
                        <th class="text-center text-white" style="width: 150px;background-color: #132d4a">PN</th>
                        <th class="text-center text-white" style="width: 150px;background-color: #132d4a">Nama</th>
                        <th class="text-center text-white" style="width: 150px;background-color: #132d4a">Jabatan</th>
                        <th class="text-center text-white" style="width: 175px;background-color: #132d4a">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="loadingTable">
                        <td colspan="5">
                          <i class="fas fa-spinner fa-spin"></i> Loading...
                        </td>
                      </tr>
                      <tr v-else-if="table_data == ''">
                        <td class="text-center" colspan="5">
                          Data Tidak Tersedia
                        </td>
                      </tr>
                      <tr
                        v-else
                        v-for="(row_data, key_data) in table_data"
                        :key="key_data"
                      >
                        <td class="text-center">
                          {{ key_data + 1 }}
                        </td>
                        <td>
                          {{ row_data.cv_pn }}
                        </td>
                        <td>{{ row_data.cv_nama }}</td>
                        <td>{{ row_data.cv_jabatan_terakhir }}</td>
                        <td class="text-center">
                          <div class="btn-group" role="group">
                            <!-- <router-link
                              :to="{
                                name: 'edit-cv',
                                params: { token: row_data.cv_id },
                              }"
                              class="btn btn-info btn-sm"
                              ><i class="fa fa-edit"></i> Edit</router-link
                            > -->
                            <router-link
                              :to="{
                                name: 'edit-cv',
                                params: { token: row_data.cv_id },
                              }"
                              class="btn btn-info btn-sm"
                              ><i class="fa fa-edit"></i> Edit</router-link
                            >
                            <!-- <router-link
                              :to="{
                                name: 'detail-cv',
                                params: { token: row_data.cv_id },
                              }"
                              class="btn btn-primary btn-sm"
                              ><i class="fa fa-eye"></i> Detail</router-link
                            > -->
                            <button v-if="role_slug == 'super_admin'" id="delete" class="btn btn-danger btn-sm" @click="hapusData(row_data.cv_id)">
                              <i class="fa fa-trash"></i> Hapus
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
  <!-- modal tambah  -->
  <b-modal
    v-model="showModal"
    title="Tambah Digitalisasi CV"
    title-class="text-black font-18"
    body-class="p-3"
    hide-footer
  >
    <form @submit.prevent="StoreData">
      <div class="row">
        <div class="col-12">
          <div class="mb-3">
            <label for="name">PN</label>
            <input type="text" v-model="pn" class="form-control" onkeypress='return event.charCode >= 48 && event.charCode <= 57' maxlength="8">
          </div>
          <div class="mb-3">
            <label for="name">Nama</label>
            <input id="name" type="text" class="form-control" v-model="nama" onkeydown="return /[a-z, ]/i.test(event.key)"
    onblur="if (this.value == '') {this.value = '';}"
    onfocus="if (this.value == '') {this.value = '';}"/>
          </div>
        </div>
      </div>

      <div class="text-end pt-5 mt-3">
        <b-button variant="light" @click="showModal = false">Close</b-button>
        <b-button type="submit" variant="success" class="ms-1">Save</b-button>
      </div>
    </form>
  </b-modal>
</template>
